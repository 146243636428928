.home-grid {
  display: grid;
  grid-template-columns: minmax(25%, 350px) minmax(75%, 100%);
  gap: 5px;
}

.product-list, .product-list__checkout {
  display: grid;
  margin: 0 auto;
  gap: 10px;
  width: 100%;
  grid-auto-rows: minmax(auto, max-content);
}

.product-list {
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}

.product-list__checkout {
  width: 100%;
  max-width: 600px;
  grid-template-columns: minmax(100%, 600px);
}

.mdc-card.product-card {
  height: max-content;
  min-height: 100%;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  box-sizing: border-box;
}

.mdc-card__primary-action.product, .mdc-card__primary-action.product-checkout {
  display: grid;
  grid-template-columns: 82px calc(100% - 192px) 90px;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  max-width: 100%;
}

.mdc-card__primary-action.product {
  grid-template-areas: "preview name price"
                       "preview description description"
                       ". customer-footer customer-footer";
}

.mdc-card__primary-action.product-checkout {
  grid-template-areas: "preview name price"
                       "preview description action"
                       ". quantity action";
}

.product.product-inline {
  flex-direction: row;
  align-items: center;
}

.product__quantity {
  grid-area: quantity;
}

.product__action {
  grid-area: action;
  justify-self: flex-end;
  padding-right: 5px;
}

.product__preview {
  grid-area: preview;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product__preview > img, .product__preview > div > img {
  object-fit: cover;
  height: 72px;
  max-width: 72px;
  border-radius: 0.375rem !important;
}

.product .product__name {
  grid-area: name;
  text-align: left;
}

.product .product__price {
  grid-area: price;
}

.product__description {
  grid-area: description;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #9fa6ad;
  text-align: left;
  padding-right: 5px;
}

.product__custom-footer {
  grid-area: custom-footer;
}

.product .product__name, .product .product__price, .product__description { 
  align-self: flex-start;
  box-sizing: border-box;
}

.product__price {
  text-align: right;
  padding: 5px;
}

/*---------text-with-bg-image----------*/

.mdc-card.text-with-bg-image {
  width: 100%;
  max-width: 412px;
  margin: 0 auto;
}

.mdc-card.text-with-bg-image .body {
  position: absolute;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.mdc-card.text-with-bg-image, .text-with-bg-image .mdc-card__primary-action {
  border-radius: 0;
}

@media all and (max-width: 575px) {
  .home-grid, .product-list {
    grid-template-columns: 100%;
  }

  .mdc-card__primary-action.product, .mdc-card__primary-action.product-checkout {
    grid-template-columns: 82px calc(100% - 182px) 90px;
  }
}
